import React from "react"

const FeatureDemoButton = ({ children, onClick, focusDemo }) => {
  const classNames = focusDemo
    ? "flex-1 mx-4 p-6 shadow-md rounded bg-primary-blue text-white"
    : "flex-1 mx-4 p-6 bg-primary-off-white text-primary-blue active:bg-primary-blue active:text-white"
  return (
    <button className={classNames} onClick={onClick}>
      {children}
    </button>
  )
}
export default FeatureDemoButton
