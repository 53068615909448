import React from "react"

const FeatureDemoContent = ({ data }) => {
  return (
    <div className="flex flex-col mt-12">
      <div className="mt-0 px-12 text-black text-base font-light">
        {data.description}
      </div>
      <img
        className="mt-4"
        src={require(`../../images/${data.imageName}.png`)}
        alt="feature demo content"
      />
    </div>
  )
}
export default FeatureDemoContent
