import React, { useState } from "react"
import FeatureDemoButton from "./FeatureDemoButton"
import FeatureDemoContent from "./FeatureDemoContent"

const FeatureDemo = ({ data }) => {
  const [currentDemoSelected, setCurrentDemoSelected] = useState("Capacity")
  const getDemoContent = (demo) =>
    data.find((featureDemo) => featureDemo.demo === demo)

  return (
    <div className="hidden mt-8 lg:flex lg:flex-col">
      <div className="flex flex-row flex-wrap">
        {data.map((feature) => (
          <FeatureDemoButton
            key={feature.id}
            onClick={() => setCurrentDemoSelected(feature.demo)}
            focusDemo={feature.demo === currentDemoSelected}
          >
            {feature.title}
          </FeatureDemoButton>
        ))}
      </div>
      <div>
        <FeatureDemoContent
          data={getDemoContent(currentDemoSelected)}
        ></FeatureDemoContent>
      </div>
    </div>
  )
}
export default FeatureDemo
