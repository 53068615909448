import React from "react"
import { PrimaryButton } from "../Button"
import FeatureDemo from "./FeatureDemo"
import FeatureDemoMobile from "./FeatureDemoMobile"

const Solutions = ({ data }) => {
  return (
    <div className="text-center justify-center bg-primary-off-white py-16 px-4 lg:py-24 lg:px-40">
      <div className="text-2xl font-semibold lg:text-3xl">
        Solutions we offer
      </div>
      <FeatureDemo data={data} />
      <FeatureDemoMobile data={data} />
      <div className="text-xl text-primary-blue font-semibold mt-12 lg:text-2xl">
        And more to come
      </div>
      <div className="text-base  my-8">
        We are constantly learning and evolving and would love to get your
        feedback on Baseline.
      </div>
      <a href="https://app.baselineteams.com/register">
        <PrimaryButton>Sign up now</PrimaryButton>
      </a>
    </div>
  )
}

export default Solutions
