import React from "react"
import FeatureDemoContentMobile from "./FeatureDemoContentMobile"

const FeatureDemoMobile = ({ data }) => {
  return (
    <div className="flex flex-col lg:hidden">
      {data.map((feature) => (
        <FeatureDemoContentMobile
          key={feature.id}
          data={feature}
        ></FeatureDemoContentMobile>
      ))}
    </div>
  )
}
export default FeatureDemoMobile
