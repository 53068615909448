import React from "react"

const CurrentProblems = ({ data }) => {
  return (
    <div className="mx-auto px-4 py-8 text-center bg-off-white lg:px-24 lg:py-16">
      <div className="text-2xl text-black font-bold lg:text-4xl lg:font-bold">
        Here is why Baseline was built in the first place
      </div>
      <div className="text-base text-black my-8">
        An efficient and cost effective resource planning tool that builds and
        manages the optimal teams for your projects.
      </div>
      <div className="container mx-auto my-8 flex flex-col px-6 lg:flex-row">
        {data.map((whyBaseline) => (
          <div
            key={whyBaseline.id}
            className="flex flex-col flex-1 my-8 lg:mx-8 lg:my-0"
          >
            <div className="px-12 lg:px-0">
              <img
                className="w-full h-auto"
                src={require(`../../images/${whyBaseline.imageName}.png`)}
                alt="why baseline content"
              />
            </div>
            <p className="text-black text-base font-light">
              {whyBaseline.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CurrentProblems
