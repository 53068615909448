import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const FeatureDemoContentMobile = ({ data }) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <div className="flex flex-col mt-12">
      <div className="text-lg font-semibold text-primary-blue">
        {data.title}
      </div>
      <div className=" px-4 mt-4 lg:px-12 text-black text-base font-light">
        {data.description}
      </div>
      <Slider {...settings}>
        {data.mobileImages.map((img) => (
          <img
            key={img}
            className="mt-4 w-full h-auto"
            src={require(`../../images/${img}.png`)}
            alt="industry demo content"
          />
        ))}
      </Slider>
    </div>
  )
}
export default FeatureDemoContentMobile
