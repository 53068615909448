import React from "react"
import MarketFitImage from "../../images/MarketFit.png"
import { SecondaryButton } from "../Button"

const MarketFit = () => {
  return (
    <div className="mx-auto py-16 px-4 text-center bg-primary-blue">
      <div className="mx-auto my-8 text-base text-white lg:w-4/6 lg:text-lg">
        There are many resource planning & project management tools available
        but most are task oriented rather than project oriented. This makes it
        difficult to consider project profitability or make hiring decisions
        tailored to capacity.
      </div>
      <img
        className="mx-auto w-full h-auto lg:w-3/6"
        src={MarketFitImage}
        alt="marketfit"
      />
      <a href="https://app.baselineteams.com/register">
        <SecondaryButton>Try for free</SecondaryButton>
      </a>
    </div>
  )
}

export default MarketFit
